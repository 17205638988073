<script setup>
const router = useRouter()
// const promo = useCookie('promo')
const to = x => router.push(x)
</script>

<template>
  <div
    class="promo"
  >
    <div class="x">
      <img
        src="/images/promo.jpg"
        loading="lazy"
        alt="Promo"
      >
    </div>
    <div class="y">
      <div class="pt">
        <h3>Uštedi vreme i novac uz našu novu online prodavnicu okova za nameštaj</h3>
        <h4>Sve što ti je potrebno za tvoj projekat pronađi na našem sajtu i naruči sa lakoćom.</h4>
        <p>
          Brza <NuxtLink
            to="/dostava-robe-i-nacini-placanja"
            class="a"
          >
            dostava
          </NuxtLink> i jednostavan
          <NuxtLink
            to="/dostava-robe-i-nacini-placanja"
            class="a"
          >
            način plaćanja
          </NuxtLink> osiguraće ti ugodno
          iskustvo online
          kupovine.
        </p>
        <vButton
          content="IZABERI OKOV ZA NAMEŠTAJ"
          class="cta"
          @click="to('/okov-za-namestaj')"
        />
      </div>
    </div>

    <!-- <div @click="promo = true" class="action">
      <svg height="24" viewBox="0 -960 960 960" width="24" fill="currentColor" class="close">
        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
      </svg>
    </div> -->
  </div>
</template>

<style scoped>
.pt {
  padding-top: 16px;
}

.new {
  margin-bottom: 16px;
}

.cta {
  margin-top: 16px;
}

h3 {
  font-weight: 800;
  font-size: 38px;
  text-wrap: balance;
  line-height: 1;
  padding-bottom: 8px;
}

h4 {
  font-size: 21px;
  text-wrap: balance;
  padding-bottom: 16px;
}

p {
  font-size: 18px;
  line-height: 1.3;
  font-size: 14px;
  padding-bottom: 16px;
  text-wrap: balance;
}

.promo {
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  min-height: 512px;
  column-gap: 24px;
  position: relative;
}

.action {
  --position: 0;
  --size: 24px;
  /* background-color: rgb(0 0 0/ .06); */
  height: var(--size);
  width: var(--size);
  display: grid;
  place-items: center;
  position: absolute;
  top: var(--position);
  right: var(--position);
  border-radius: 50%;
  color: rgb(var(--color-rgb) / .25);
}

.a {
  font-weight: 600;
  text-decoration: underline;
  color: var(--brand-color);
}

.x {
  background-color: rgb(var(--color-rgb) / .03);
  height: 100%;
  position: relative;
  grid-column: 1 / span 4;
  border-radius: 4px;

  & img {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.y {
  grid-column: 5 / span 2;
}

@media (max-width: 512px) {
  .promo {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    margin-top: 32px;
    min-height: auto;
    gap: 0;
  }

  .action {
    --size: 24px;
    color: white;
  }

  .x {
    display: none;
  }

  .cta {
    justify-content: center;
    & svg {
      display: none;
    }
  }
}

@media (hover:hover) {
  .action:hover {
    cursor: pointer;

  }
}
</style>
